<template>
  <div>
    <dialog-edit :value="value" @input="$emit('close')" :maxWidth="`${mw}px`" @keydown.esc="$emit('input')">
      <template v-slot:title> {{ data.title }} </template>
      <v-row>
        <v-col cols="12" sm="6" class="px-2">
          <material-card color="primary">
            <template v-slot:heading>
              <div class="px-0 py-0">Объект</div>
            </template>
            <a-form-view :value="dataOffer" :model="modelLeft" :config="{ dense: true, hideNull: !true }" @click="onClick($event)" />
          </material-card>
        </v-col>
        <v-col cols="12" sm="6" class="px-2">
          <material-card color="primary">
            <template v-slot:heading>Предложение клиенту</template>
            <a-form-model v-model="data" :model="modelRight" :errors="errors" :config="config" @validate="validate($event)" class="px-4" />
          </material-card>
        </v-col>
        <v-col cols="12" class="px-3">
          <material-card color="primary">
            <template v-slot:heading>Информация из объявления</template>
            <a-form-model v-if="m" v-model="data" :model="calcModel('detail1', 'form', m)" :errors="errors" :config="config" @validate="validate($event)" class="px-4" />
            <a-form-model
              v-if="m"
              v-model="dataOffer"
              :model="calcModel('detail1', 'form', m.offer)"
              :errors="errors"
              :config="config"
              @validate="validate($event)"
              class="px-4"
            />
          </material-card>
        </v-col>
        <v-col cols="12" class="px-3">
          <material-card color="primary">
            <template v-slot:heading>
              <div class="px-3 py-0 d-flex flex-wrap" style="justify-content: space-between">
                <div class="align-self-center">Фото без водяных знаков</div>
              </div>
            </template>
            <a-form-model
              v-model="data"
              :model="[{ name: 'data.images', type: 'imageslite', actions: 'hide', size: 250 }]"
              :errors="errors"
              :config="config"
              @validate="validate($event)"
              class="px-4"
            />
          </material-card>
        </v-col>
      </v-row>
      <div v-if="loading" class="loading-block">
        <div class="loader"></div>
      </div>
      <confirm-dialog ref="confirmDialog"></confirm-dialog>

      <a-loader v-if="loading" />
      <template v-slot:actions>
        <v-btn v-if="getAccess('object.print')" :href="getPreview()" target="_blank" class="mx-1" dark color="info" title="PDF">
          <v-icon dark>fas fa-file-pdf </v-icon>
        </v-btn>
        <v-btn v-if="getAccess('object.print')" :href="getPreview('html')" target="_blank" class="mx-1" dark color="info" title="HTML">
          <v-icon dark>fas fa-file-code </v-icon>
        </v-btn>

        <v-spacer></v-spacer>
        <v-btn v-if="id" @click="removeDialogShow = true">
          <v-icon>far fa-trash-alt</v-icon>
          <btn-title v-if="$vuetify.breakpoint.smAndUp">Удалить</btn-title>
        </v-btn>
        <v-btn @click="_submit()" title="" :color="isChanged ? 'warning' : ''">
          <v-icon>far fa-save</v-icon>
          <btn-title v-if="$vuetify.breakpoint.smAndUp">Сохранить</btn-title>
        </v-btn>
        <v-btn @click="$emit('close')">
          <v-icon>far fa-window-close</v-icon>
          <btn-title v-if="$vuetify.breakpoint.smAndUp">Закрыть</btn-title>
        </v-btn>
      </template>
    </dialog-edit>
    <remove-dialog v-model="removeDialogShow" @remove="remove(api, id)" :title="removeTitle" />
  </div>
</template>

<script>
//import parser from "./../parser";

import { autoHeightBlock, getAccess, popupMenu, genModel, getForm, submitForm, removeEl } from "../../components/mixings";
export default {
  mixins: [autoHeightBlock, getAccess, popupMenu, genModel, getForm, submitForm, removeEl],
  components: {},
  props: {
    idShow: Number,
    value: Boolean,
  },
  data() {
    return {
      //  id: 0,
      loading: false,
      title: "",
      m: this.$store.getters["config/get"].models.myOffer,
      showEditDialog: false,
      removeDialogShow: false,
      removeTitle: "Вы действительно хотите удалить запись?",
      blockHeight: 256,
      isInfoProcess: false,
      configFormOffer: null,
      dataOffer: {},
      mw: 1200,
    };
  },
  created() {
    //this.$root.title = this.m.title;
    this.loading = true;
    if (this.id) this.updateData(this.id);
    this.mw = 200;
    this.$nextTick(() => {
      this.mw = 1200;
    });
    console.log("data", this.data);
  },
  mounted() {},

  computed: {
    isInfo() {
      let res = false;
      if (this.data?.description || (this.data?.ads_info?.data && this.data?.ads_info?.data?.resp.status !== 429)) res = true;
      return res;
    },
    isPhoto() {
      if (this.dataOffer.images && this.dataOffer.images.length) return true;
      return false;
    },
    id() {
      return this.idShow || Number(this.$route.params.id) || 0;
    },
    url() {
      return this.m.url;
    },
    api() {
      return this.m.url;
    },
    modelLeft() {
      let model = this.calcModel("viewForm1", "form", this.m.offer);
      return model;
    },
    modelRight() {
      let model = this.calcModel("viewForm2");
      return model;
    },
    model: {
      get() {
        let config = JSON.parse(JSON.stringify(this.m?.config.default || {}));
        let model = this.calcModelConfig(config);
        return model;
      },
    },
    modelOffer: {
      get() {
        let m = this.m.offer;
        let config = JSON.parse(JSON.stringify(m?.config.default || {}));
        let model = this.calcModelConfig(config, m);
        return model;
      },
    },
    config() {
      return { dense: true, readonly: false, hideDetails: "auto" };
    },
    configForm() {
      return this.m.config.default;
    },
  },
  watch: {
    value(v) {
      if (v) {
        this.updateData(this.id);
        console.log(this.data);
      }
    },
    showEditDialog() {
      if (!this.showEditDialog) {
      }
    },
  },
  methods: {
    async updateData_(id) {
      this.updateData(id);
    },
    afterFetchData() {
      if (!this.data.data.images) this.setData("data.images", this.data?.offer?.images || []);
      this.setOfferData(this.data?.offer || {}, this.modelOffer);
      // if (!this.data?.description && this.data?.offer?.description) this.data.description = this.data?.offer?.description;
    },
    setOfferData(data, model) {
      let n = "dataOffer";
      this.dataOffer = {};
      console.log(n, data);
      for (const name in data) {
        if (name == "data" && this[n]?.data && data[name]) {
          for (const nameData in data.data) {
            this.$set(this[n].data, nameData, data.data[nameData]);
          }
        } else {
          this.$set(this[n], name, data[name]);
        }
      }
      for (let el of model) {
        if (el?.formula) {
          this.makeFormulaField(el, this.dataOffer);
        }
      }
      console.log(this.dataOffer);
    },
    getPreview(type = "pdf") {
      if (type == "pdf") return this.$root.config.baseUrl + this.m.url + `/print/${this.id}?token=` + localStorage.getItem("user-token");
      return this.$root.config.baseUrl + this.m.url + `/printH/${this.id}?token=` + localStorage.getItem("user-token");
    },
    _submit() {
      if (this.readonly) return;
      const fields = this.configForm?.fieldsPost || "";
      console.log(this.configForm, fields);
      this.submit(false, fields);
    },
    afterRemove(data) {
      this.removeDialogShow = false;
      this.$emit("close");
    },
    itemShowClose() {
      if (this.idShow) this.$emit("close");
      else this.$router.push({ name: this.$route.path.split("/")[1] });
    },

    onClick(e) {
      if (e.field?.isMenu) {
        this.showPopupMenu(e.field.name, e.row, e.event, e);
      }
    },

    async saveOffer(data) {
      try {
        let response = await this.$axios.post(this.url, data);
        let status = response.data.status == "ok";
        if (response.data.data) {
          this.$root.$emit("show-info", {
            text: "Данные записаны",
          });
        }
      } catch (error) {
        this.$root.$emit("show-info", {
          type: "error",
          text: "Ошибка при сохранении",
        });
      }
    },
  },
};
</script>
